import * as Turbo from "@hotwired/turbo";
import { Controller } from "@hotwired/stimulus";
import { applySnapshot, getSnapshot } from "mobx-state-tree";

import { radiansToDegrees } from "../../../helpers/geometry";
import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";
import { alertDialog } from "../../components/ir_dialog/helper";
import UndoQueue from "../../../da/map/undo-queue";
import throttle from "lodash/throttle";
import { fromLonLat } from "ol/proj";
import BuildingImagePlacementCreator from "../../../da/map/building-images/placement-creator";
import BuildingImagePlacementsTransformInteractionManager from "../../../da/map/interaction-managers/transform/building-image-placement";
import { EDITOR_MODE_SELECT } from "../../../da/layout-editor/helpers/toolbar-constants";

export default class MapBaseController extends Controller {
  connect() {
    this.projectData = JSON.parse(this.element.dataset.project);
    this.authToken = new URL(location.href).searchParams.get("auth_token");
    this.savePath = this.element.dataset.savePath;
    if (this.authToken) {
      this.savePath = `${this.savePath}?auth_token=${this.authToken}`;
    }
    this.bingApiKey = this.element.dataset.bingApiKey;
    this.azureApiKey = this.element.dataset.azureApiKey;
    this.openLayersTileProvider = this.element.dataset.openLayersTileProvider;
    this.page = this.element.dataset.page;
    this.dirty = false;
    this.debugMode = this.element.dataset.debugMode;

    this.element[this.identifier] = this;
    this.element.mapController = this;
    if (!this.suppressGlobalCreation) window.mapController = this;

    this.throttledAddSnapshotToUndoQueue = throttle(this.addSnapshotToUndoQueue, 100, { trailing: true });

    this.buildingImagePlacementTransformInteractionManagers = [];
  }

  // Rotate map clockwise
  rotateCounterClockwise(event) {
    let degrees = -1;
    if (event.shiftKey) degrees = -10;
    if (event.altKey) degrees = -0.1;
    if (event.shiftKey && event.altKey) degrees = -0.01;
    this.mapManager.rotateBy(degrees);
  }

  rotateClockwise(event) {
    let degrees = 1;
    if (event.shiftKey) degrees = 10;
    if (event.altKey) degrees = 0.1;
    if (event.shiftKey && event.altKey) degrees = 0.01;
    this.mapManager.rotateBy(degrees);
  }

  // When clicking the compass, rotate back to 0 (North)
  rotateNorth(_event) {
    this.mapManager.rotateTo(0);
  }

  zoomOut(event) {
    let amount = -0.25;
    if (event.shiftKey) amount = -1;
    if (event.altKey) amount = -0.05;
    if (event.shiftKey && event.altKey) amount = -0.01;
    this.mapManager.zoomBy(amount);
  }

  zoomIn(event) {
    let amount = 0.25;
    if (event.shiftKey) amount = 1;
    if (event.altKey) amount = 0.05;
    if (event.shiftKey && event.altKey) amount = 0.01;
    this.mapManager.zoomBy(amount);
  }

  get isViewerPage() {
    return this.page === "viewer";
  }

  get isRoofPlanesPage() {
    return this.page === "roofPlanes";
  }

  get isRoofSectionsPage() {
    return this.page === "roofSections";
  }

  get isEditArrayPage() {
    return this.page === "editArray";
  }

  markDirty({ force } = { force: false }) {
    this.dirty = this.project.needsSave;

    if (this.dirty || force) {
      this.saveBtnTarget.classList.add("ol-map__btn--dirty");
      this.populateStatusbar();
    } else {
      this.saveBtnTarget.classList.remove("ol-map__btn--dirty");
    }
  }

  populateStatusbar() {
    // When a value like rotation updates, we need to wait for OL to finish doing its thing
    setTimeout(() => {
      this.setZoomAndRotationInStatusBar();
      this.setMapSpecificStatusBarItems();
    }, 500);
  }

  setZoomAndRotationInStatusBar() {
    if (this.hasStatusItemZoomTarget) {
      this.statusItemZoomTarget.innerText = this.mapManager.zoom.toFixed(3);
    }

    if (this.hasStatusItemRotationTarget) {
      this.statusItemRotationTarget.innerText = `${radiansToDegrees(this.mapManager.rotation).toFixed(2)}°`;
    }
  }

  setMapSpecificStatusBarItems() {
    // override in sub class
  }

  get isDirty() {
    return this.dirty;
  }

  markClean() {
    this.dirty = false;
    this.saveBtnTarget.classList.remove("ol-map__btn--dirty");
  }

  removeSelectedFeatures(event) {
    this.selectInteractionManager.removeSelectedFeatures(event);
    this.removeSelectedBuildingImagePlacements();
    this.markDirty();
  }

  saveAndThenRedirect(url) {
    const event = {};
    this.save(event, () => Turbo.visit(url));
  }

  alignViewToFeature = (feature, options = { markClean: true }) => {
    const view = this.mapManager.map.getView();
    const geometry = feature.getGeometry();
    view.fit(geometry, { padding: [100, 30, 80, 30] });

    if (!options.markClean) return;

    setTimeout(() => {
      this.markClean();
    }, 50);
  };

  onValidationError = (model, json) => {
    const title = "Unable to Save";

    let message = "<p>We encountered the following errors:</p>";
    message += "<ul>";
    json.errorMessages.forEach((errorMessage) => {
      message += `<li>${errorMessage}</li>`;
    });
    message += "</ul>";

    this.#errorAlert({ model, title, message });
  };

  onServerError = (model, response, json = {}) => {
    const title = `Server Error (${response.status})`;

    let message;
    if (json.message) {
      message = `<p>${json.message}</p>`;
    } else {
      message =
        "<p>We encountered a server error while trying to process your request. An admin has been notified of the problem.  We apologize for the inconvenience.</p>";
    }

    this.#errorAlert({ model, title, message });
  };

  setEnabledForBtnGroup(group, enabled) {
    const enableDisable = enabled ? "enable" : "disable";
    const buttons = this.btnGroups[group];
    toolbarBtn[enableDisable](...buttons);
  }

  #errorAlert({ model, title, message }) {
    if (this.hasSaveBtnTarget) {
      setTimeout(() => {
        toolbarBtn.hideSpinner(this.saveBtnTarget);
      }, 500);
    }

    if (model.setNotSaving) model.setNotSaving();

    alertDialog(message, () => {}, {
      title,
      headerColor: "red",
      confirmBtnColor: "red",
    });
  }

  addSnapshotToUndoQueue(enableUndoBtn = true) {
    if (!this.hasUndoBtnTarget) return;

    if (!this.undoQueue) {
      this.undoQueue = new UndoQueue();
    }

    const newSnapshot = getSnapshot(this.project);
    if (!newSnapshot) debugger;
    this.undoQueue.push(newSnapshot);

    if (enableUndoBtn && this.undoQueue.hasUndoSteps) toolbarBtn.enable(this.undoBtnTarget);
    toolbarBtn.disable(this.redoBtnTarget);
  }

  undo(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    const snapshot = this.undoQueue.undo();
    toolbarBtn.enable(this.redoBtnTarget);
    if (this.undoQueue.hasZeroUndoSteps) toolbarBtn.disable(this.undoBtnTarget);
    this.undoRedoApplySnapshot(snapshot);
  }

  redo(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    const snapshot = this.undoQueue.redo();
    toolbarBtn.enable(this.undoBtnTarget);
    if (this.undoQueue.hasZeroRedoSteps) toolbarBtn.disable(this.redoBtnTarget);
    this.undoRedoApplySnapshot(snapshot);
  }

  undoRedoApplySnapshot(snapshot) {
    if (!snapshot) return;

    this.undoRedoInProgress = true;
    if (this.undoRedoInProgressResetTimeout) {
      clearTimeout(this.undoRedoInProgressResetTimeout);
    }
    this.mapManager.clearFeaturesBeforeSnapshotUpdate();
    try {
      applySnapshot(this.project, snapshot);
    } catch (e) {
      debugger;
    }
    this.mapManager.renderFeaturesAfterSnapshotUpdate();

    this.project.setDirty(true);
    this.markDirty();

    // Need to give the map time to register the onMoveEnd event
    this.undoRedoInProgressResetTimeout = setTimeout(() => {
      this.undoRedoInProgress = false;
    }, 1000);
  }

  updateProjectSiteValuesForOtherMaps(otherMapController) {
    if (!otherMapController) return;

    // Need to give the map time to finish rotating, zooming, moving, etc.
    setTimeout(() => {
      const projectSite = this.project.projectSite;
      const viewerData = {
        viewerLat: projectSite.viewerLat,
        viewerLng: projectSite.viewerLng,
        viewerRotation: projectSite.viewerRotation,
        viewerZoom: projectSite.viewerZoom,
      };

      otherMapController.skipNextMapMoveEndEvent = true;
      otherMapController.setProjectSiteValuesFromOtherMap(viewerData);
    }, 500);
  }

  setProjectSiteValuesFromOtherMap(newValues) {
    this.project.projectSite.setViewerSettings({
      rotation: newValues.viewerRotation,
      lat: newValues.viewerLat,
      lng: newValues.viewerLng,
      zoom: newValues.viewerZoom,
    });

    const coordinate = fromLonLat([newValues.viewerLng, newValues.viewerLat]);

    this.mapManager.setZoomRotationAndPosition(newValues.viewerZoom, newValues.viewerRotation, coordinate);
  }

  clearUndoQueue() {
    if (!this.undoQueue) return;

    this.undoQueue.clear();
    toolbarBtn.disable(this.undoBtnTarget);
    toolbarBtn.disable(this.redoBtnTarget);
  }

  createBuildingImagePlacement({ imagePath, uploadedBuildingImageId, width, height, feetPerPixel }) {
    const creator = new BuildingImagePlacementCreator(
      this.mapManager,
      imagePath,
      uploadedBuildingImageId,
      width,
      height,
      feetPerPixel,
    );
    const placement = creator.create();

    this.markDirty();

    const feature = this.mapManager.addBuildingImagePlacementToMap(placement);
    const interactionManager = this.addBuildingImagePlacementTransformInteractionManager(feature);
    this.activateSelectMode();

    interactionManager.transform.setSelection([feature]);

    feature.set("selected", true);
    feature.changed();
  }

  // startSelectMode only handles adding and removing interaction managers.  It does not handle changing
  // the editor mode or updating the menu item because it is intended to be called from a button click.
  activateSelectMode() {
    this.startSelectMode();

    this.editorMode = EDITOR_MODE_SELECT;
    this.editorModeMenuTarget.selectGroupController.selectOption(EDITOR_MODE_SELECT);
    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  addBuildingImagePlacementTransformInteractionManager(feature) {
    const interactionManager = new BuildingImagePlacementsTransformInteractionManager(this, feature);
    this.buildingImagePlacementTransformInteractionManagers.push(interactionManager);
    return interactionManager;
  }

  addBuildingImagePlacementTransformInteractions() {
    this.buildingImagePlacementTransformInteractionManagers.forEach((interactionManager) => {
      interactionManager.add();
    });
  }

  removeBuildingImagePlacementTransformInteractions() {
    this.buildingImagePlacementTransformInteractionManagers.forEach((interactionManager) => {
      interactionManager.remove();
    });
  }

  setupBuildingImagePlacementInteractionManagers() {
    this.mapManager.buildingImagePlacementFeatures.forEach((feature) => {
      const interactionManager = this.addBuildingImagePlacementTransformInteractionManager(feature);
      if (this.editorMode === EDITOR_MODE_SELECT) {
        interactionManager.add();
      }
    });
  }

  removeSelectedBuildingImagePlacements() {
    const interactionManagers = [];
    this.buildingImagePlacementTransformInteractionManagers.forEach((im) => {
      if (im.isSelected) {
        im.remove();

        const uuid = im.placementUuid;
        this.mapManager.removeBuildingImagePlacementFromMap(uuid);

        this.project.destroyBuildingImagePlacement(uuid);
      } else {
        interactionManagers.push(im);
      }
    });

    this.buildingImagePlacementTransformInteractionManagers = interactionManagers;
  }

  // Note that this is called after an uploaded building image is destroyed on the server
  // so we don't need to figure out if the placement models have been persisted or not.  If
  // they're persisted, they would have already been removed from the backend.  We don't need
  // to pass a "_destroy" flag to the backend.
  removeUploadedBuildingImage(id) {
    const uploadedBuildingImage = this.project.getUploadedBuildingImage(id);
    const placementUuids = uploadedBuildingImage.buildingImagePlacements.map((bip) => bip.uuid);

    placementUuids.forEach((uuid) => {
      this.mapManager.removeBuildingImagePlacementFromMap(uuid);
      this.removeBuildingImagePlacementInteractionManager(uuid);
    });

    this.project.destroyUploadedBuildingImage(id);
  }

  removeBuildingImagePlacementInteractionManager(placementUuid) {
    const interactionManager = this.buildingImagePlacementTransformInteractionManagers.find(
      (im) => im.placementUuid === placementUuid,
    );
    if (!interactionManager) return;

    interactionManager.remove();
    this.buildingImagePlacementTransformInteractionManagers =
      this.buildingImagePlacementTransformInteractionManagers.filter((im) => im.placementUuid !== placementUuid);
  }
}
