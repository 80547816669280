import XYZ from "ol/source/XYZ";
import { toSize } from "ol/size";
import { createCanvasContext2D } from "ol/dom";

export default class GridTileSource extends XYZ {
  constructor(argOptions = {}) {
    const defaultOptions = {
      gridDivisions: 20,
      tileSize: 256,
      wrapX: true,
      projection: "EPSG:3857",
      url: "z:{z} x:{x} y:{y}",
      backgroundColor: "#DEF7FF",
      strokeColor: "#BAE5F4",
    };

    const options = { ...defaultOptions, ...argOptions };

    super({
      projection: options.projection,
      wrapX: options.wrapX,
      zDirection: options.zDirection,
      url: options.url,
      tileLoadFunction: (tile, _text) => this.loadGridTile(tile, _text, options),
    });
  }

  loadGridTile(tile, _text, options) {
    const z = tile.getTileCoord()[0];
    const tileSize = toSize(this.tileGrid.getTileSize(z));
    const context = createCanvasContext2D(tileSize[0], tileSize[1]);

    context.fillStyle = options.backgroundColor;
    context.fillRect(0, 0, tileSize[0], tileSize[1]);

    context.strokeStyle = options.strokeColor;
    context.strokeRect(0.5, 0.5, tileSize[0] - 0.5, tileSize[1] - 0.5);

    const cellSize = tileSize[0] / options.gridDivisions;
    for (let x = cellSize; x < tileSize[0]; x += cellSize) {
      context.beginPath();
      context.moveTo(x, 0);
      context.lineTo(x, tileSize[1]);
      context.stroke();
    }

    for (let y = cellSize; y < tileSize[1]; y += cellSize) {
      context.beginPath();
      context.moveTo(0, y);
      context.lineTo(tileSize[0], y);
      context.stroke();
    }

    tile.setImage(context.canvas);
  }
}
