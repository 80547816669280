import { types } from "mobx-state-tree";
import WithDirtyTracking from "./with-dirty-tracking";
import BuildingImagePlacementModel, { buildingImagePlacementPersistenceData } from "./building-image-placement-model";
import { v4 as uuid } from "uuid";

const UploadedBuildingImageBase = types
  .model("UploadedBuildingImageModel", {
    id: types.maybe(types.integer),
    imagePath: types.string,
    width: types.number,
    height: types.number,
    buildingImagePlacements: types.array(BuildingImagePlacementModel),
  })
  .views((self) => ({
    get project() {
      return getParent(self, 1);
    },
    get needsSave() {
      return self.dirty || self.buildingImagePlacements.some((bip) => bip.needsSave);
    },
  }))
  .actions((self) => ({
    addBuildingImagePlacement({ latLngPoints, feetPerPixel }) {
      const model = BuildingImagePlacementModel.create({
        uploadedBuildingImageId: self.id,
        uuid: uuid(),
        latLngPoints,
        feetPerPixel,
        dirty: true,
      });

      self.buildingImagePlacements.push(model);
      self.markDirty();

      return model;
    },
    destroyPlacement(placement) {
      if (placement.isPersisted) {
        placement.flagDeleted();
      } else {
        self.buildingImagePlacements = self.buildingImagePlacements.filter((bip) => bip.uuid !== placement.uuid);
      }
      self.markDirty();
    },
  }));

export function uploadedBuildingImagePersistenceData(uploadedBuildingImage) {
  const result = {
    id: uploadedBuildingImage.id,
    building_image_placements_attributes: [],
  };

  uploadedBuildingImage.buildingImagePlacements.forEach((bip) => {
    if (!bip.needsSave) return;

    result.building_image_placements_attributes.push(buildingImagePlacementPersistenceData(bip));
  });

  return result;
}

const UploadedBuildingImageModel = types.compose(UploadedBuildingImageBase, WithDirtyTracking);
export default UploadedBuildingImageModel;
