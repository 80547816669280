import * as animate from "./animate";

// Sometimes you want to do something like validation errors in
// JavaScript.  This class provides a simple way to manage errors
// and display them in an IR alert element.
//
// The class accepts a reference to the IR alert element, probably
// placed somewhere on the page with a `.d-none` class applied to
// it.
//
// Commonly you'll do something like the following in Stimulus
// controller:
//
// submit(event) {
//   if (!this.isValid) return;
//   // ... do something if valid
// }
//
// get isValid() {
//   const errors = new AlertErrorManager(this.errorMessageTarget);
//
//   if (this.myFieldTarget.value === "") {
//     errors.add("Please enter a value);
//   }
//
//   errors.showHide();
//
//   return errors.empty;
// }
export default class AlertErrorManager {
  errors = [];

  constructor(errorsAlertElem) {
    this.errorsAlertElem = errorsAlertElem;
  }

  add(error) {
    this.errors.push(error);
  }

  get has() {
    return this.length > 0;
  }

  get empty() {
    return this.length === 0;
  }

  get length() {
    return this.errors.length;
  }

  show() {
    if (this.length === 1) {
      this.errorContentContainer.innerHTML = this.errors[0];
    } else if (this.length > 1) {
      this.errorContentContainer.innerHTML = `<ul><li>${this.errors.join("</li><li>")}</li></ul>`;
    }

    animate.show(this.errorsAlertElem);
  }

  hide() {
    this.errorContentContainer.innerHTML = "";
    animate.hide(this.errorsAlertElem);
  }

  showHide() {
    this.has ? this.show() : this.hide();
  }

  get errorContentContainer() {
    return this.errorsAlertElem.querySelector(".ir-alert__content");
  }
}
