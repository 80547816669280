import { mercatorAdjustedLengthInMeters } from "../../ol-helpers";

export default class DrawnLine {
  constructor(controller) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.buildingImageFeature = controller.buildingImageFeature;
    this.linesVectorSource = this.mapManager.scaleLinesVectorSource;
    const uuid = this.buildingImageFeature.get("uuid");
    this.placement = this.controller.project.getBuildingImagePlacement(uuid);
  }

  get exists() {
    return this.lineFeatures.length > 0;
  }

  get lineFeatures() {
    return this.linesVectorSource.getFeatures();
  }

  get feature() {
    return this.lineFeatures[0];
  }

  get coordinates() {
    return this.feature.getGeometry().getCoordinates();
  }

  get lengthMeters() {
    const rawLength = this.feature.getGeometry().getLength();
    return mercatorAdjustedLengthInMeters(this.map, rawLength);
  }

  get lengthFeet() {
    return this.lengthMeters * METERS_TO_FEET;
  }

  get enteredLengthFeet() {
    return this.controller.lineLength;
  }

  get buildingImageMetersPerPixel() {
    const polygonCoordinates = this.buildingImageFeature.getGeometry().getCoordinates();
    const ring = polygonCoordinates[0];

    const [x0, y0] = ring[0];
    const [x1, y1] = ring[1];

    const dx = x1 - x0;
    const dy = y1 - y0;

    const rawWidthMeters = Math.sqrt(dx * dx + dy * dy);
    const adjustedWidthMeters = mercatorAdjustedLengthInMeters(this.map, rawWidthMeters);
    const imageWidthPixels = this.placement.width;

    return adjustedWidthMeters / imageWidthPixels;
  }

  get lengthInOriginalPixels() {
    if (!this.exists) return 0;

    return this.lengthMeters / this.buildingImageMetersPerPixel;
  }

  get feetPerPixel() {
    if (!this.exists || !this.enteredLengthFeet) return null;

    return this.enteredLengthFeet / this.lengthInOriginalPixels;
  }
}
