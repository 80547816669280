import { applySnapshot } from "mobx-state-tree";

const ERRORS_THAT_DONT_GET_REPORTED = [422, 404];
const ERRORS_WITH_SYSTEM_LEVEL_MESSAGE = [401, 403, 404, 500];

export function requestHeaders() {
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfMetaTag ? csrfMetaTag.getAttribute("content") : "no-token";

  return {
    "X-CSRF-Token": csrfToken,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "X-Tab-Id": window.sessionStorage.getItem("tabId"),
    Accept: "application/json",
  };
}

function reportErrors(action, response) {
  if (!response.ok && !ERRORS_THAT_DONT_GET_REPORTED.includes(response.status)) {
    const message = `${response.status} ${response.statusText}: ${action} to ${response.url}`;
    reportError(message);
  }
}

export function reportError(message) {
  if (process.env.NODE_ENV !== "test") {
    // then we are running in browser
    console.log(`[Api] ${message}`);
  }
}

function processResponseWithJsonPayload({
  response,
  model,
  onSuccess = undefined,
  onValidationError = undefined,
  onServerError = undefined,
}) {
  if (ERRORS_WITH_SYSTEM_LEVEL_MESSAGE.includes(response.status)) {
    if (onServerError) {
      response.json().then(
        (json) => {
          onServerError(model, response, json);
        },
        (thing) => {
          onServerError(model, response);
        },
      );
    }
    return;
  }

  response.json().then(
    (json) => {
      if (response.status === 422) {
        if (onValidationError) {
          onValidationError(model, json);
        } else {
          applySnapshot(model.errors, json);
        }
      } else {
        const modelData = json.model ? json.model : json;
        applySnapshot(model, modelData);

        if (onSuccess) onSuccess(json);
      }
    },
    (thing) => {
      reportError("Failed to parse JSON");
    },
  );
}

function processResponseWithoutJsonPayload(response, _model) {
  if (ERRORS_WITH_SYSTEM_LEVEL_MESSAGE.includes(response.status)) {
    const message = `${response.status} ${response.statusText}`;
    reportError(message);
  }
}

function processResponseNetworkError(model) {
  const message = "Network error: Could not connect to server";
  reportError(message);
}

export function get({ model, path, onSuccess = undefined, onValidationError = undefined, onServerError = undefined }) {
  return (
    window
      .fetch(path, {
        headers: requestHeaders(),
      })
      // Elliot: Added this .then so we can see whether the promise solves or rejects
      .then(
        (response) => {
          reportErrors("GET", response);
          processResponseWithJsonPayload({
            response,
            model,
            onSuccess,
            onValidationError,
            onServerError,
          });
        },
        (_response) => {
          processResponseNetworkError(model);
        },
      )
  );
}

function putPost({ model, path, body, action, onSuccess, onValidationError, onServerError }) {
  return window
    .fetch(path, {
      method: action,
      headers: requestHeaders(),
      body: JSON.stringify(body),
    })
    .then(
      (response) => {
        reportErrors(action, response);
        processResponseWithJsonPayload({
          response,
          model,
          onSuccess,
          onValidationError,
          onServerError,
        });
      },
      (response) => {
        processResponseNetworkError(model);
      },
    );
}

export function post({
  model,
  path,
  body,
  onSuccess = undefined,
  onValidationError = undefined,
  onServerError = undefined,
}) {
  return putPost({ model, path, body, action: "POST", onSuccess, onValidationError, onServerError });
}

export function put({
  model,
  path,
  body,
  onSuccess = undefined,
  onValidationError = undefined,
  onServerError = undefined,
}) {
  return putPost({ model, path, body, action: "PUT", onSuccess, onValidationError, onServerError });
}

export function destroy({ model, path, onSuccess = undefined }) {
  if (model.id) {
    return window
      .fetch(path, {
        method: "DELETE",
        headers: requestHeaders(),
      })
      .then(
        (response) => {
          reportErrors("DELETE", response);
          processResponseWithoutJsonPayload(response, model);
          if (onSuccess) onSuccess();
        },
        (response) => {
          processResponseNetworkError(model);
        },
      );
  } else {
    return Promise.resolve(onSuccess());
  }
}
