import { LineString } from "ol/geom";

// This is called while drawing the line.  If the user holds the "c" key, the line will be constrained to the
// horizontal or vertical of the browser window.  Note that after drawing the line becomes points on the map
// so if the map is rotated the line will rotate with the map and will no longer be horizontal or vertical
// in the browser window.
//
// This is added to a draw interaction constructor as the geometryFunction option.
// e.g. geometryFunction: (coordinates, geometry) =>
//   constrainDrawGeometryFunction(coordinates, geometry, this.map, this.constrainPressed),
// The interaction manager class should have a map property pointing to the OL map instance and it should have event handlers that set the constrainPressed property.
// e.g.
// handleKeyDown = (event) => {
//   if (event.key.toLowerCase() === "c") {
//     this.constrainPressed = true;
//   }
// };
// handleKeyUp = (event) => {
//   if (event.key.toLowerCase() === "c") {
//     this.constrainPressed = false;
//   }
// };
// See app/javascript/da/map/interaction-managers/measure/base.js for an example.
export const constrainDrawGeometryFunction = (coordinates, geometry, map, constrainPressed) => {
  if (!geometry) geometry = new LineString([]);
  if (coordinates.length === 2 && constrainPressed) {
    const [start, end] = coordinates;
    const startPixel = map.getPixelFromCoordinate(start);
    const endPixel = map.getPixelFromCoordinate(end);
    const dx = Math.abs(endPixel[0] - startPixel[0]);
    const dy = Math.abs(endPixel[1] - startPixel[1]);
    if (dx > dy) {
      // Lock to horizontal
      endPixel[1] = startPixel[1];
    } else {
      // Lock to vertical
      endPixel[0] = startPixel[0];
    }
    const adjustedEnd = map.getCoordinateFromPixel(endPixel);
    coordinates[1] = adjustedEnd;
  }
  geometry.setCoordinates(coordinates);
  return geometry;
};
