import { Controller } from "@hotwired/stimulus";
import { v4 as uuid } from "uuid";

import { logger } from "../helpers/app";
import { addTabIdToForm } from "../helpers/form";
export default class extends Controller {
  headerName = "X-Tab-Id";

  connect() {
    this.ensureTabId();

    document.addEventListener("turbo:before-fetch-request", this.#onTurboBeforeFetch);
    document.addEventListener("ajax:beforeSend", this.#onAjaxBeforeSend);
    document.addEventListener("submit", this.#onFormSubmit);
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.#onTurboBeforeFetch);
    document.removeEventListener("ajax:beforeSend", this.#onAjaxBeforeSend);
    document.removeEventListener("submit", this.#onFormSubmit);
  }

  ensureTabId() {
    if (!window.sessionStorage.getItem("tabId")) {
      const tabId = uuid();
      logger(`[tabId] setting in sessionStorage: ${tabId}`);
      window.sessionStorage.setItem("tabId", tabId);
    }

    this.tabId = window.sessionStorage.getItem("tabId");
  }

  // Using class fields with the # syntax for private methods
  // so they're automatically bound to 'this' (arrow functions),
  // which makes event removal simpler.
  #onTurboBeforeFetch = (event) => {
    logger("[tabId] in turbo:before-fetch-request handler");
    const { fetchOptions } = event.detail;
    fetchOptions.headers[this.headerName] = this.tabId;
  };

  #onAjaxBeforeSend = (event) => {
    const [xhr, options] = event.detail;
    logger("[tabId] in ajax:beforeSend handler", xhr, options);
    // setRequestHeader concatenates if called multiple times
    if (!xhr.tabIdSet) {
      xhr.setRequestHeader(this.headerName, this.tabId);
      xhr.tabIdSet = true;
    }
  };

  #onFormSubmit = (event) => {
    const form = event.target;
    // Only attach a hidden input if not a Turbo or remote form
    if (form.getAttribute("data-turbo") !== "false" && form.dataset.remote !== "false") {
      return;
    }

    addTabIdToForm(form);
  };
}
