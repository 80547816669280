import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

import { showDialog } from "./components/ir_dialog/helper";
import { logger } from "../helpers/app";

export default class ProjectUpdatedNoticeController extends Controller {
  static values = {
    projectId: Number,
    updatingTabId: String,
  };

  static show(_projectId, updatingTabId, currentTabId) {
    logger(`[tabId] from sessionStorage: ${currentTabId}`);
    logger(`[tabId] from project updated broadcast: ${updatingTabId}`);

    if (updatingTabId === currentTabId) return;

    showDialog("project-updated-notice-dialog");
  }

  static debouncedShow = debounce(ProjectUpdatedNoticeController.show, 1000);

  connect() {
    ProjectUpdatedNoticeController.debouncedShow(this.projectIdValue, this.updatingTabIdValue, this.currentTabId);
  }

  get updatingTabId() {
    return this.updatingTabIdValue;
  }

  get currentTabId() {
    return window.sessionStorage.getItem("tabId");
  }
}
