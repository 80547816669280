import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

import { SCALE_LINE_DATA_TYPE } from "../data-types";
import MapManager from "../map-manager";
import { scaleLineStyle } from "../styles/scale-line";

export default class ScaleBuildingImageMapManager extends MapManager {
  constructor(controller) {
    super(controller);
  }

  rotateCompassTo(_radians) {
    // Overriding this method to do nothing.  We don't have a compass on this map.
  }

  add() {
    const mapParams = {
      layers: [this.tileLayer, this.visualMarkersVectorLayer, this.scaleLinesVectorLayer],
    };

    this.map = this.buildMap(mapParams);

    this.rotateTo(0); // For this map we don't care about the project map rotation.

    this.addBuildingImagePlacementLayers();
  }

  get scaleLinesVectorLayer() {
    if (!this.memoScaleLinesVectorLayer) {
      this.scaleLinesVectorSource = new VectorSource();

      this.memoScaleLinesVectorLayer = new VectorLayer({
        source: this.scaleLinesVectorSource,
        style: scaleLineStyle("final"),
        zIndex: 10,
      });
      this.memoScaleLinesVectorLayer.set("dataType", SCALE_LINE_DATA_TYPE);
    }
    return this.memoScaleLinesVectorLayer;
  }

  clearScaleLines() {
    this.scaleLinesVectorSource.clear();
  }
}
