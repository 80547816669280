import { Controller } from "@hotwired/stimulus";

import * as animate from "../../../helpers/animate";
import AlertErrorManager from "../../../helpers/alert-error-manager";
import { flashlightAndRemoveClass } from "../../../helpers/app";

export default class extends Controller {
  static targets = [
    "imageInchesField",
    "realInchesField",
    "imageDpiField",
    "feetPerPixelField",
    "calculateErrorMessage",
    "addToMapErrorMessage",
  ];

  static outlets = ["da--layout-editor--scale-building-image"];

  // This is used in a dialog, which is rendered in the DOM and then moved to be a child of the
  // body element, which results in a double connect.  We only want to connect this controller
  // once, so we use this flag to check if we've already connected.
  connectedOnce = false;

  connect() {
    if (!this.connectedOnce) {
      this.connectedOnce = true;
      return;
    }

    this.addEnterHandlers();
  }

  disconnect() {
    this.removeEnterHandlers();
  }

  addEnterHandlers() {
    this.imageInchesFieldTarget.addEventListener("keydown", this.enterCalculateHandler);
    this.realInchesFieldTarget.addEventListener("keydown", this.enterCalculateHandler);
    this.imageDpiFieldTarget.addEventListener("keydown", this.enterCalculateHandler);
  }

  enterCalculateHandler = (event) => {
    if (event.key === "Enter") {
      this.calculate();
    }
  };

  removeEnterHandlers() {
    this.imageInchesFieldTarget.removeEventListener("keydown", this.enterCalculateHandler);
    this.realInchesFieldTarget.removeEventListener("keydown", this.enterCalculateHandler);
    this.imageDpiFieldTarget.removeEventListener("keydown", this.enterCalculateHandler);
  }

  calculate() {
    if (!this.isValid) return;

    const scaleFactorInchPerInch = this.realInches / this.imageInches;
    const realInchesPerPixel = scaleFactorInchPerInch / this.imageDpi;
    const feetPerPixel = realInchesPerPixel / 12;

    this.feetPerPixelFieldTarget.value = feetPerPixel;

    flashlightAndRemoveClass(this.feetPerPixelFieldTarget);
  }

  get imageInches() {
    return parseFloat(this.imageInchesFieldTarget.value);
  }

  get realInches() {
    return parseFloat(this.realInchesFieldTarget.value);
  }

  get imageDpi() {
    return parseFloat(this.imageDpiFieldTarget.value);
  }

  get feetPerPixel() {
    return parseFloat(this.feetPerPixelFieldTarget.value);
  }

  get isValid() {
    const errors = new AlertErrorManager(this.calculateErrorMessageTarget);

    if (isNaN(this.imageInches) || this.imageInches === 0) {
      errors.add("Please enter a value for the image scale");
    }

    if (isNaN(this.realInches) || this.realInches === 0) {
      errors.add("Please enter a value for the real scale");
    }

    if (isNaN(this.imageDpi) || this.imageDpi === 0) {
      errors.add("Please enter a value for the image DPI");
    }

    errors.showHide();

    return errors.empty;
  }

  addScaledToMap(_event) {
    const outlet = this.daLayoutEditorScaleBuildingImageOutlet;

    const errors = new AlertErrorManager(this.addToMapErrorMessageTarget);
    const feetPerPixel = this.feetPerPixel;

    if (isNaN(feetPerPixel) || feetPerPixel === 0) {
      errors.add("Please enter a value for the feet per pixel or use the calculator above to calculate the value");
    }

    errors.showHide();

    if (errors.has) return;

    outlet.addManualScaledToMap(feetPerPixel);

    this.clearFields();
  }

  clearFields() {
    this.imageInchesFieldTarget.value = "";
    this.realInchesFieldTarget.value = "";
    this.feetPerPixelFieldTarget.value = "";
  }

  hideCalculateError() {
    animate.hide(this.calculateErrorMessageTarget);
  }

  showCalculateError() {
    animate.show(this.calculateErrorMessageTarget);
  }
}
