import MapBaseController from "./map_base_controller";

import BxProjectModel from "../../../bx/models/project-model";
import PrProjectModel from "../../../pr/models/project-model";

import { showDialog, hideDialog } from "../../components/ir_dialog/helper";

import ScaleBuildingImageMapManager from "../../../da/map/map-managers/scale-building-image";
import MapModelSynchronizer from "../../../da/map/map-model-synchronizer";
import BuildingImagePlacementCreator from "../../../da/map/building-images/placement-creator";
import ScaleLineInteractionManager from "../../../da/map/interaction-managers/scale-line/base";
import DrawnLine from "../../../da/map/interaction-managers/scale-line/drawn-line";

import { EDITOR_MODE_DRAW } from "../../../da/layout-editor/helpers/toolbar-constants";
import AlertErrorManager from "../../../helpers/alert-error-manager";

export default class extends MapBaseController {
  static targets = ["map", "doNotScaleImage", "form", "errorMessage", "tabsContainer"];

  static outlets = ["da--layout-editor--manual-scale-building-image"];

  static values = {
    projectType: String,
  };

  // This is used in a dialog, which is rendered in the DOM and then moved to be a child of the
  // body element, which results in a double connect.  We only want to connect this controller
  // once, so we use this flag to check if we've already connected.
  connectedOnce = false;

  connect() {
    if (!this.connectedOnce) {
      this.connectedOnce = true;
      return;
    }

    this.suppressGlobalCreation = true;

    super.connect();

    if (this.projectTypeValue === "bx") {
      this.project = BxProjectModel.create(this.projectData);
    } else if (this.projectTypeValue === "pr") {
      this.project = PrProjectModel.create(this.projectData);
    }

    this.mapModelSynchronizer = new MapModelSynchronizer(this);

    this.mapManager = new ScaleBuildingImageMapManager(this);
    this.mapManager.add();

    this.scaleLineInteractionManager = new ScaleLineInteractionManager(this);

    this.editorMode = EDITOR_MODE_DRAW;
    this.initializeEditorMode();

    this.element.mapController = this;
    window.scaleMapController = this;

    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
    if (this.scaleLineInteractionManager) {
      this.scaleLineInteractionManager.remove();
    }
  }

  openWithImageData(imageData, mapController) {
    this.imageData = imageData;
    this.mapController = mapController;

    this.doNotScaleImageTarget.src = imageData.imagePath;

    this.setInitialTab();

    this.daLayoutEditorManualScaleBuildingImageOutlet.clearFields();

    this.clearOutPreviousBuildingImage();

    this.mapManager.clearScaleLines();
    this.clearEnteredLineLength();

    this.addNewImageToDataModelAndMap();

    this.openDialog();
  }

  openDialog() {
    // Make the dialog appear at the top middle of the browser window
    const clickPosition = { x: window.innerWidth / 2, y: 0 };
    showDialog(this.dialog, () => {}, { clickPosition, showNearClickPosition: true });
  }

  setInitialTab() {
    this.tabsContainerTarget.tabsController.setTabWithValueToActive("line-scale");
  }

  clearOutPreviousBuildingImage() {
    if (this.buildingImagePlacement) {
      this.mapManager.removeBuildingImagePlacementFromMap(this.buildingImagePlacement.uuid);
      this.project.destroyBuildingImagePlacement(this.buildingImagePlacement.uuid);
    }
  }

  addNewImageToDataModelAndMap() {
    const creator = new BuildingImagePlacementCreator(
      this.mapManager,
      this.imageData.imagePath,
      this.imageData.uploadedBuildingImageId,
      this.imageData.width,
      this.imageData.height,
    );
    this.buildingImagePlacement = creator.create();
    this.buildingImageFeature = this.mapManager.addBuildingImagePlacementToMap(this.buildingImagePlacement);
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_DRAW:
        this.startDrawMode();
        break;
      default:
        this.startDrawMode();
    }
  }

  startDrawMode(_event) {
    this.editorMode = EDITOR_MODE_DRAW;
    this.scaleLineInteractionManager.add();
  }

  handleKeydown = (event) => {
    if (event.key === "Escape") {
      this.scaleLineInteractionManager.finish();
    }
  };

  addScaledToMap(event) {
    event.preventDefault();

    const drawnLine = new DrawnLine(this);
    if (!this.isValid(drawnLine)) return;

    const feetPerPixel = drawnLine.feetPerPixel;
    const imageData = { ...this.imageData, feetPerPixel };
    this.createPlacement(imageData);
  }

  addManualScaledToMap(feetPerPixel) {
    const imageData = { ...this.imageData, feetPerPixel };
    this.createPlacement(imageData);
  }

  addUnscaledToMap(event) {
    event.preventDefault();
    this.createPlacement(this.imageData);
  }

  createPlacement(data) {
    this.mapController.createBuildingImagePlacement(data);
    hideDialog(this.dialog);
  }

  isValid(drawnLine) {
    const errors = new AlertErrorManager(this.errorMessageTarget);

    if (!drawnLine.exists) {
      errors.add("Please draw a line on the image");
    }

    if (!this.hasLineLength) {
      errors.add("Please enter a line length");
    }

    errors.showHide();

    return errors.empty;
  }

  get hasLine() {
    return false;
  }

  get lineLength() {
    return this.lineLengthField.value;
  }

  get lineLengthField() {
    return this.formTarget.querySelector("input[name='da_scaled_building_image[length]']");
  }

  clearEnteredLineLength() {
    this.lineLengthField.value = "";
  }

  get hasLineLength() {
    return this.lineLength !== "" && this.lineLength != 0;
  }

  get dialog() {
    return document.querySelector(`[data-dialog-identifier="scale-building-image-dialog"]`);
  }
}
