import Transform from "ol-ext/interaction/Transform";

export default class BuildingImagePlacementsTransformInteractionManager {
  constructor(controller, feature) {
    this.controller = controller;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.project = controller.project;

    this.feature = feature;
    this.placementUuid = feature.get("uuid");

    this.feature.set("selected", false);
    this.added = false;
  }

  add() {
    if (this.added) return;

    this.transform = new Transform({
      features: [this.feature],
      keepAspectRatio: () => true,
      stretch: false,
    });

    this.startImageAngle = this.feature.get("imageRotation");

    this.transform.on("select", this.selectHandler);
    this.transform.on("rotatestart", this.rotateScaleStartHandler);
    this.transform.on("rotating", this.rotatingHandler);
    this.transform.on(["rotateend", "scaleend", "translateend"], this.rotateScaleTranslateEndHandler);

    this.mapManager.map.addInteraction(this.transform);

    this.added = true;
  }

  selectHandler = (e) => {
    const selected = e.feature ? true : false;
    this.feature.set("selected", selected);
  };

  rotateScaleStartHandler = (e) => {
    this.startImageAngle = e.feature.get("imageRotation");
  };

  rotatingHandler = (e) => {
    e.feature.set("imageRotation", this.startImageAngle - e.angle);
  };

  rotateScaleTranslateEndHandler = (_e) => {
    this.mapModelSynchronizer.saveBuildingImagePlacementCoordinatesAndRotationToModel(this.feature);
  };

  remove() {
    if (!this.added) return;

    this.transform.un("select", this.selectHandler);
    this.transform.un("rotatestart", this.rotateScaleStartHandler);
    this.transform.un("rotating", this.rotatingHandler);
    this.transform.un(["rotateend", "scaleend", "translateend"], this.rotateScaleTranslateEndHandler);

    this.mapManager.map.removeInteraction(this.transform);
    this.feature.set("selected", false);

    this.added = false;
  }

  get isSelected() {
    return this.feature.get("selected");
  }
}
