import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import XYZ from "ol/source/XYZ";

import GridTileSource from "./grid-tile-source";

export default class TileLayerFactory {
  constructor(provider, providerApiKey) {
    this.provider = provider;
    this.providerApiKey = providerApiKey;
  }

  get tileLayer() {
    return new TileLayer({
      visible: true,
      preload: Infinity,
      source: this.#source,
    });
  }

  get #source() {
    switch (this.provider) {
      case "bing":
        return this.#bingSource;
      case "azure":
        return this.#azureSource;
      case "grid":
        return this.#gridSource;
      default:
        new Error(`Unknown tile provider: ${this.provider}`);
    }
  }

  get #bingSource() {
    return new BingMaps({ key: this.providerApiKey, imagerySet: "AerialWithLabelsOnDemand" });
  }

  get #azureSource() {
    const url = "https://atlas.microsoft.com/map/tile";
    const params = [
      `subscription-key=${this.providerApiKey}`,
      "api-version=2.0",
      "tilesetId=microsoft.imagery",
      "zoom={z}",
      "x={x}",
      "y={y}",
    ];
    const azureMapsUrlTemplate = `${url}?${params.join("&")}`;
    return new XYZ({ url: azureMapsUrlTemplate });
  }

  get #gridSource() {
    return new GridTileSource();
  }
}
