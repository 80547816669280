import { Controller } from "@hotwired/stimulus";

import * as animate from "../../helpers/animate";

export default class IrTabs extends Controller {
  static targets = ["link", "content"];
  static values = { activeTabClass: { type: String, default: "ir-content-tabs__link--active" } };

  connect() {
    const activeIndex = this.#activeIndex;

    this.linkTargets.forEach((linkTarget, i) => {
      const addRemove = i === activeIndex ? "add" : "remove";
      linkTarget.classList[addRemove](this.activeTabClassValue);
    });

    this.contentTargets.forEach((contentTarget, i) => {
      if (i !== activeIndex) {
        contentTarget.classList.add("d-none");
      }
    });

    this.element.tabsController = this;
  }

  setTabWithValueToActive(tabValue) {
    let selectedIndex = 0;

    this.linkTargets.forEach((linkTarget, i) => {
      if (linkTarget.dataset.tabValue === tabValue) selectedIndex = i;

      this.toggleLink(linkTarget, linkTarget.dataset.tabValue === tabValue);
    });

    this.contentTargets.forEach((contentTarget, i) => {
      this.toggleContent(contentTarget, i === selectedIndex);
    });
  }

  toggleLink(linkTarget, isActive) {
    if (isActive) {
      linkTarget.dataset.active = true;
      linkTarget.classList.add(this.activeTabClassValue);
    } else {
      delete linkTarget.dataset.active;
      linkTarget.classList.remove(this.activeTabClassValue);
    }
  }

  toggleContent(contentTarget, isActive) {
    if (isActive) {
      animate.show(contentTarget);
    } else {
      animate.hide(contentTarget, { fadeOut: false });
    }
  }

  toggle(event) {
    event.preventDefault();

    const currentActiveIndex = this.#activeIndex;
    const newActiveIndex = this.linkTargets.findIndex((linkTarget) => linkTarget === event.currentTarget);

    if (currentActiveIndex === newActiveIndex) return;

    this.linkTargets.forEach((linkTarget, i) => {
      this.toggleLink(linkTarget, i === newActiveIndex);
    });

    this.contentTargets.forEach((contentTarget, i) => {
      this.toggleContent(contentTarget, i === newActiveIndex);
    });
  }

  get #activeIndex() {
    let result = 0;
    this.linkTargets.forEach((linkTarget, i) => {
      if (linkTarget.dataset.active) result = i;
    });
    return result;
  }
}
