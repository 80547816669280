import { Draw } from "ol/interaction";

import { scaleLineStyle } from "../../styles/scale-line";
import { SCALE_LINE_DATA_TYPE } from "../../data-types";
import { constrainDrawGeometryFunction } from "../helpers";

export default class Base {
  constructor(controller) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.scaleLinesVectorSource = this.mapManager.scaleLinesVectorSource;
  }

  add() {
    if (this.currentScaleLineInteraction) return;

    this.constrainPressed = false;
    this.attachKeyboardListeners();
    this.clearCurrentInteraction();

    this.currentScaleLineInteraction = new Draw({
      source: this.scaleLinesVectorSource,
      type: "LineString",
      maxPoints: 2,
      style: scaleLineStyle("drawing"),
      geometryFunction: (coordinates, geometry) =>
        constrainDrawGeometryFunction(coordinates, geometry, this.map, this.constrainPressed),
    });
    this.map.addInteraction(this.currentScaleLineInteraction);
    this.currentScaleLineInteraction.on("drawstart", this.onDrawStart);
    this.currentScaleLineInteraction.on("drawend", this.onDrawEnd);
  }

  attachKeyboardListeners() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  detachKeyboardListeners() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  handleKeyDown = (event) => {
    if (event.key.toLowerCase() === "c") {
      this.constrainPressed = true;
    }
  };

  handleKeyUp = (event) => {
    if (event.key.toLowerCase() === "c") {
      this.constrainPressed = false;
    }
  };

  remove() {
    if (!this.currentScaleLineInteraction) return;

    this.detachKeyboardListeners();
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentScaleLineInteraction) {
      this.map.removeInteraction(this.currentScaleLineInteraction);
      delete this.currentScaleLineInteraction;
      this.currentScaleLineInteraction = undefined;
    }
  }

  onDrawStart = (_event) => {
    this.clearOutLastFeature();
  };

  onDrawEnd = (event) => {
    const scaleLineFeature = event.feature;
    scaleLineFeature.set("dataType", SCALE_LINE_DATA_TYPE);
  };

  finish() {
    if (!this.currentScaleLineInteraction) return;

    this.currentScaleLineInteraction.finishDrawing();
    this.clearOutLastFeature();
  }

  clearOutLastFeature() {
    const drawingSource = this.mapManager.scaleLinesVectorSource;
    const features = drawingSource.getFeatures();
    const lastFeature = features[features.length - 1];
    drawingSource.removeFeature(lastFeature);
  }
}
