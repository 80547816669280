import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

import { showDialog, getDialogElem } from "./components/ir_dialog/helper";

export default class CompanySwitchedNoticeController extends Controller {
  static values = {
    updatingTabId: String,
    newCompanyName: String,
    oldCompanyName: String,
  };

  static show(updatingTabId, currentTabId, newCompanyName, oldCompanyName) {
    if (updatingTabId === currentTabId) return;

    const dialog = getDialogElem("company-switched-notice-dialog");
    dialog.querySelector("[data-identifier='old-company-name']").textContent = `"${oldCompanyName}"`;
    dialog.querySelector("[data-identifier='new-company-name']").textContent = `"${newCompanyName}"`;

    showDialog("company-switched-notice-dialog");
  }

  static debouncedShow = debounce(CompanySwitchedNoticeController.show, 1000);

  connect() {
    CompanySwitchedNoticeController.debouncedShow(
      this.updatingTabIdValue,
      this.currentTabId,
      this.newCompanyNameValue,
      this.oldCompanyNameValue,
    );
  }

  get currentTabId() {
    return window.sessionStorage.getItem("tabId");
  }
}
