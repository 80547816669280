import { Point, LineString } from "ol/geom";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";

const COLOR_DRAWING = "rgba(241, 0, 200, 0.5)";
const COLOR_FINAL = "rgba(241, 0, 200, 1.0)";

const strokeDrawing = new Stroke({ color: COLOR_DRAWING, width: 3 });
const fillDrawing = new Fill({ color: COLOR_DRAWING });
const circleDrawing = new CircleStyle({ radius: 5, fill: fillDrawing, stroke: strokeDrawing });

const strokeFinal = new Stroke({ color: COLOR_FINAL, width: 3 });

function createEndCapLine(lineGeometry, resolution, whichEnd, offsetPx) {
  const coords = lineGeometry.getCoordinates();
  if (coords.length < 2) return null;

  let i1, i2;
  if (whichEnd === "last") {
    i1 = coords.length - 2;
    i2 = coords.length - 1;
  } else {
    i1 = 1;
    i2 = 0;
  }

  const [x1, y1] = coords[i1];
  const [x2, y2] = coords[i2];

  const dx = x2 - x1;
  const dy = y2 - y1;
  const length = Math.sqrt(dx * dx + dy * dy);
  if (!length) return null;

  let nx = -dy / length;
  let ny = dx / length;

  const offsetMapUnits = offsetPx * resolution;
  const xEnd = x2;
  const yEnd = y2;

  const p1 = [xEnd + nx * offsetMapUnits, yEnd + ny * offsetMapUnits];
  const p2 = [xEnd - nx * offsetMapUnits, yEnd - ny * offsetMapUnits];

  return new LineString([p1, p2]);
}

export function scaleLineStyle(type) {
  if (type === "drawing") {
    return scaleLineDrawingStyle;
  } else if (type === "final") {
    return scaleLineFinalStyle;
  }
}

function scaleLineDrawingStyle(feature, resolution) {
  const geometry = feature.getGeometry();
  const type = geometry.getType();
  const styles = [];

  if (type === "LineString") {
    styles.push(new Style({ stroke: strokeDrawing }));

    const coords = geometry.getCoordinates();
    if (coords.length > 1) {
      styles.push(
        new Style({
          geometry: new Point(coords[0]),
          image: circleDrawing,
        }),
      );
      styles.push(
        new Style({
          geometry: (f) => createEndCapLine(f.getGeometry(), resolution, "last", 10),
          stroke: strokeDrawing,
        }),
      );
      styles.push(
        new Style({
          geometry: new Point(coords[coords.length - 1]),
          image: circleDrawing,
        }),
      );
    }
  } else if (type === "Point") {
    styles.push(new Style({ image: circleDrawing }));
  }

  return styles;
}

function scaleLineFinalStyle(feature, resolution) {
  const geometry = feature.getGeometry();
  const type = geometry.getType();
  const styles = [];

  if (type === "LineString") {
    styles.push(new Style({ stroke: strokeFinal }));

    styles.push(
      new Style({
        geometry: (f) => createEndCapLine(f.getGeometry(), resolution, "first", 6),
        stroke: strokeFinal,
      }),
    );

    styles.push(
      new Style({
        geometry: (f) => createEndCapLine(f.getGeometry(), resolution, "last", 6),
        stroke: strokeFinal,
      }),
    );
  }

  return styles;
}
