import React from "react";
import ReactDOMServer from "react-dom/server";
import { sentryException } from "../config/sentry";

// this allows us to call the function from a Rails JS "sprinkle" response
if (!("IR_GLOBALS" in window)) window.IR_GLOBALS = {};

const updateIdentifiedElement = (identifier, content) => {
  const element = document.querySelector(`[data-identifier='${identifier}']`);
  if (element) {
    element.innerHTML = content;
  } else {
    const message = `[Handled Error] Could not find data-identifier "${identifier}" on page`;
    logger(message);
    sentryException(message);
  }
};
window.IR_GLOBALS.updateIdentifiedElement = updateIdentifiedElement;

export const createLinkAndClick = (href, target = "_self") => {
  const link = document.createElement("a");
  link.setAttribute("target", target);
  link.setAttribute("href", href);
  document.body.appendChild(link);
  link.click();
};

export const inchesToFeetAndInches = (inches, precision = 0) => {
  const feet = Math.floor(inches / 12);
  const remainingInches = Number.parseFloat(inches % 12).toFixed(IR_GLOBALS.dimensionLabelsPrecision || precision);
  if (Number.parseFloat(remainingInches) === 0) return `${feet}'`;
  if (Number.parseFloat(remainingInches) === 12) {
    return `${feet + 1}'`;
  }
  return `${feet}' ${remainingInches}"`;
};

export const elementInfo = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const top = rect.top + scrollTop;
  const left = rect.left + scrollLeft;
  const { height, width } = rect;
  const bottom = top + height;
  const right = left + width;
  const center = { x: left + width / 2, y: top + height / 2 };
  return { top, right, bottom, left, height, width, center };
};

export const pointOverlapsElement = ({ x, y, element }) => {
  if (element.offsetParent !== null) {
    const rect = element.getBoundingClientRect();

    if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
      return true;
    }
  }

  return false;
};

// Note: your callback will not execute if the tracking code is blocked by a browser ad/privacy block
export const track = ({ action = "System Support", category, label, callback = () => {} }) => {
  // gtag("event", action, {
  //   event_category: category,
  //   event_label: label,
  //   transport_type: "beacon",
  //   event_callback: callback,
  // });
};

export const showFlashMessage = ({ message, flashType }) => {
  const allowedTypes = ["error", "success", "info"];
  if (!allowedTypes.includes(flashType)) {
    throw `${flashType} is not a recognized flash type`;
  }

  const flashMessageDiv = ReactDOMServer.renderToString(
    <div className="ir-flash__container" data-controller="components--ir-flash">
      <div
        className={`ir-flash ir-flash--${flashType}`}
        data-components--ir-flash-target="flash"
        data-action="mouseover->components--ir-flash#mouseOver mouseout->components--ir-flash#mouseOut"
      >
        <a href="#" className="ir-flash__close" data-action="components--ir-flash#close">
          &times;
        </a>
        {message}
      </div>
    </div>,
  );

  let flashWrapper = document.querySelector("#ir-flash-messages");
  if (!flashWrapper) {
    flashWrapper = document.createElement("div");
    flashWrapper.setAttribute("id", "#ir-flash-messages");
    document.body.appendChild(flashWrapper);
  }

  flashWrapper.innerHTML = flashMessageDiv;
};

window.IR_GLOBALS.showFlashMessage = showFlashMessage;

export const scaleMap = (num, inMin, inMax, outMin, outMax) => {
  return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export function benchmark(message, runnable) {
  const startTime = new Date();
  const result = runnable();
  const endTime = new Date();

  console.log(message, endTime - startTime, "ms");
  return result;
}

export function logger(message, value = undefined) {
  if (onDevelopment() || onStaging()) {
    // then we are running on development
    console.log(`[Log] ${message}`);
    if (value) console.log(value);
  }
}

export function onDevelopment() {
  return applicationEnvironment() === "development";
}

export function onStaging() {
  return applicationEnvironment() === "staging";
}

export function applicationEnvironment() {
  const metaTag = document.querySelector('meta[name="application-environment"]');
  if (!metaTag) return "test";

  return metaTag.getAttribute("content");
}

export function buildAlphabeticalIdentifier(position) {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  let identifier = "";
  if (position >= 26) {
    identifier += letters[Math.floor(position / 26) - 1];
    identifier += letters[position % 26];
  } else {
    identifier += letters[position];
  }

  return identifier;
}

export function showBtnSpinner(btn) {
  btn.classList.add("ir-btn--spinner-shown");
  btn.disabled = true;
}

export function hideBtnSpinner(btn) {
  btn.classList.remove("ir-btn--spinner-shown");
  btn.disabled = false;
}

export function humanizeWattage(wattage) {
  if (wattage < 1000) {
    return `${wattage} W`;
  } else if (wattage <= 999995) {
    const wattageKw = wattage / 1000.0;
    return `${wattageKw.toFixed(2)} kW`;
  } else {
    const wattageMw = wattage / 1000000.0;
    return `${wattageMw.toFixed(2)} MW`;
  }
}

export function getZIndex(e) {
  let z = 10050;

  try {
    z = document.defaultView.getComputedStyle(e).getPropertyValue("z-index");
    if (isNaN(z)) z = getZIndex(e.parentNode);
  } catch (error) {
    // Remove import of SentryException when this is removed
    const message = "[Handled Error] getZIndex failure";
    const extras = {
      e: JSON.stringify(e, ["id", "className", "tagName"]),
      error: error,
    };
    console.log(message, extras);
    sentryException(message, extras);
  }

  return Number.parseInt(z);
}

export function isVisible(element) {
  return element.offsetParent !== null;
}

export function valueInSteps(value, precision = 1, step = 0.5) {
  const originalValue = Number.parseFloat(value);
  const originalInteger = Number.parseInt(originalValue);
  const originalDecimal = originalValue - originalInteger;

  const stepCount = Math.round(originalDecimal / step);
  const scaledDecimal = stepCount * step;

  const adjustedValue = originalInteger + scaledDecimal;

  return `${adjustedValue.toFixed(precision)}`;
}

export function flashlightAndRemoveClass(element) {
  element.classList.add("flashlight");
  setTimeout(() => {
    element.classList.remove("flashlight");
  }, 2000);
}
