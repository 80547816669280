import { sentryException } from "../config/sentry";
import { showFlashMessage } from "./app";

export const RESPONSE_ERROR_401_MESSAGE = "Access denied.  You may have been logged out.";

export const RESPONSE_ERROR_403_MESSAGE =
  "Access denied.  You do not appear to have permission to carry out that action.";

export const RESPONSE_ERROR_500_MESSAGE =
  "We experienced a server error.  We've been notified of the problem and apologize for the inconvenience.";

export const REQUEST_ERROR_MESSAGE = "The server did not respond.  Please try again.";

export function defaultHeaders() {
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfMetaTag ? csrfMetaTag.getAttribute("content") : "no-token";
  const tabId = window.sessionStorage.getItem("tabId");

  return {
    "X-CSRF-Token": csrfToken,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "X-Tab-Id": tabId,
  };
}

export const errorHandler = (error) => {
  if (typeof error === "string") {
    sentryException(error);
    return;
  }

  const status = error.response ? error.response.status : undefined;

  if ([401, 403, 404].includes(status)) {
    // If we encounter one of these, let the standard Rails request/response mechanism deal with it.
    window.location.reload();
  } else if (status === 500) {
    // Let the user know, but the backend should notify us of the 500
    showFlashMessage({
      message: "Sorry, we encountered a server error. An admin has been notified.",
      flashType: "error",
    });
  } else {
    sentryException(error);
    console.error(error);
  }
};

export const errorTypeAndMessageFromErrorObj = (error) => {
  let message;
  let type;
  if (error.response) {
    type = "response";
    const { status } = error.response;
    if (status === 401) {
      message = RESPONSE_ERROR_401_MESSAGE;
    } else if (status === 403) {
      message = RESPONSE_ERROR_403_MESSAGE;
    } else if (status === 500) {
      message = RESPONSE_ERROR_500_MESSAGE;
    } else {
      message = `${status} error occurred`;
    }
  } else if (error.request) {
    type = "request";
    message = REQUEST_ERROR_MESSAGE;
  } else {
    type = "code";
    message = error.message;
  }
  return { type, message };
};
