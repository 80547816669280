import { Controller } from "@hotwired/stimulus";
import { track, createLinkAndClick } from "../helpers/app";
import { submitWithTabId } from "../helpers/form";

export default class extends Controller {
  trackLink = (event) => {
    event.preventDefault();

    const link = event.currentTarget;
    const url = link.href;
    const { category, action, label } = this.trackingInfoFromElement(link);

    // give tracking code time to execute
    setTimeout(() => {
      createLinkAndClick(url, "_blank");
    }, 100);

    track({ action, category, label });
  };

  trackFormSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const { category, action, label } = this.trackingInfoFromElement(form);

    // give tracking code time to execute
    setTimeout(() => {
      submitWithTabId(form);
    }, 100);

    track({ action, category, label });
  };

  trackingInfoFromElement(el) {
    const category = el.dataset.trackingCategory;
    const action = el.dataset.trackingAction;
    const label = el.dataset.trackingLabel;
    return { category, action, label };
  }
}
